import { useState, createContext, useContext, useEffect, useCallback } from "react";
import liff from '@line/liff';
import { AppContext } from "@store/ContextStore";
import { requestFn } from "@utils/request";
import * as apis from '@config/apis';
import util from 'util';
import { setSessionStore ,getSessionStore, getLocalStore , setLocalStore } from "@utils/util";
import { useSnackbar } from 'notistack';
import CryptoJS from 'crypto-js';
import config from '@config/config.json';
import { Redirect, useHistory } from "react-router-dom";
import { IUser } from "@interface/IUser";

export const LineLIFFContext = createContext({
  liff,
  error:null
});

type LineLIFFProviderProps = {
  liffId:string ,
  children: any
}
export const useLineLiFFState = () => useContext(LineLIFFContext);

export const LineLIFFProvider = ( props:LineLIFFProviderProps) => {

  const [error , setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { state , dispatch } = useContext(AppContext);
  const history = useHistory();
  
  const { enqueueSnackbar } = useSnackbar();
  const defaultValue = {
    liff,
    error
  };

  const getUserInfoRequest = useCallback(()=>{
    setLoading(true);
    const fetchingData = async () =>{
      const response = await requestFn(dispatch, {
        url: util.format(apis.GET_USER_INFO_API ),
        method: 'get'
      });
      setLoading(false);
      if (response?.status === 200) {
        if(response?.data.code === 200){
          let data = response?.data.user as IUser;
      
          console.group("Init Line get user info");
          console.log(data);
          console.groupEnd();
          dispatch({
            type: 'CHANGE_USER_INFO',
            payload: {
              lineId: data.lineId  ,
              name:data.name  , 
              mail:data.email ,
              isModified:data.isModified
            }
          });
        } 
      } else {
        enqueueSnackbar(`伺服器暫無回應，請稍後在試` , {variant:"info"});
      }
    }
    
    fetchingData();
    
  },[dispatch]);
  
  const postLineInfoRequest = useCallback((lineId:string , name:string , mail:string|undefined)=>{

    setLoading(true);
    
    const fetchingData = async () =>{
          
      const response = await requestFn(dispatch, {
          url: util.format(apis.POST_LINE_API),
          method: 'post',
          data: {
            name:name,
            lineId:lineId,
            mail:mail ,
            code : "DaySmartCity"
          }
      })
      
      setLoading(false);
      if (response && response.status === 200 && response.data) {
        setSessionStore(`account_token`,response.data.token );
        setLocalStore(`refresh_token` , response.data.refreshToken);
        getUserInfoRequest();
      } else {
        enqueueSnackbar(`No Register Line User` , {variant:"error"});
      }
    }
    
    fetchingData();
    
  },[dispatch]);
  
  const initLine = () => {
    liff.init({ liffId : props.liffId }, () => {
      
      if (liff.isLoggedIn()) {
        
        if(getSessionStore("account_token") || getLocalStore("refresh_token")){
          getUserInfoRequest();
          
        } else {
          liff.getProfile().then((profile) => {
            dispatch({
              type: 'CHANGE_USER_INFO',
              payload: {
                lineId: profile.userId  ,
                name:profile.displayName  , 
                mail:liff.getDecodedIDToken()?.email ? liff.getDecodedIDToken()!!.email!! : "" ,
                isModified:true
              }
            });
            postLineInfoRequest(profile.userId ,profile.displayName, liff.getDecodedIDToken()?.email );
            
          }).catch((err: any) => {
            enqueueSnackbar("Init Line GetProfile Error" , {variant:"error"});
          });
        }
        
      } else {
        liff.login();
      }
      
    }, (err: any) => {
      enqueueSnackbar(`Line Liff init error : ${err} ` , {variant:"error"});
    });
  }
  useEffect(()=>{
    initLine();
  },[]);
  return (
    <LineLIFFContext.Provider value={defaultValue}>
      {props.children}
    </LineLIFFContext.Provider>
  );
};
