
import ChargeBoxActions from '@store/actions/chargeBoxActions';

/** State 接口 */
export interface ChargeBoxState {
  chargeBoxId: string
  cost :number
}

/** 初始狀態 */
export const INITIAL_CHARGEBOX: ChargeBoxState = {
  chargeBoxId: "",
  cost :0
}



/** 默認 reducer */
export default function chargeBoxReducer(state: ChargeBoxState = INITIAL_CHARGEBOX, action: ChargeBoxActions) {
  switch (action.type) {
    case 'CHANGE_CHARGEBOX': {
      return {
        ...state,
        chargeBoxId: action.payload.chargeBoxId,
        cost : action.payload.cost,
      }
      
    }

    default:
      return state
  }
}
