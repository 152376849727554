
import loadable from '@loadable/component';
import Loading from '@components/Loading';
import { IPageProps } from '@components/Page';


const Main = loadable( () => import('@views/Main') , {
    fallback:<Loading />,
} );

const ChargeBox = loadable( () => import('@views/ChargeBox') , {
    fallback:<Loading />,
} );
  
const QRReader = loadable( () => import('@views/QRReader') , {
    fallback:<Loading />,
} );

const Pay = loadable( () => import('@views/Pay') , {
    fallback:<Loading />,
} );
  
const Map = loadable( () => import('@views/GoogleMap') , {
    fallback:<Loading />,
} );
  
const Privacy = loadable( () => import('@views/Privacy') , {
    fallback:<Loading />,
} );

const Terms = loadable( () => import('@views/TermsOfService') , {
    fallback:<Loading />,
} );

const User = loadable( () => import('@views/User') , {
    fallback:<Loading />,
} );
const ChoosePayment = loadable( () => import('@views/Pay/ChoosePayment') , {
    fallback:<Loading />,
} );


export const routes:Array<IPageProps> = [
    {
        path:"/",
        title:"DaySmart" ,
        component : Main ,
        exact : true 
    },
    {
        path:"/chargebox",
        title:"充電資訊" ,
        component : ChargeBox ,
        exact : true 
    },
    {
        path:"/user",
        title:"使用者資訊" ,
        component : User ,
        exact : true 
    },
    {
        path:"/qrcode",
        title:"" ,
        component : QRReader ,
        exact : true 
    },
    {
        path:"/pay",
        title:"付款資訊" ,
        component : Pay ,
        exact : true 
    },
    {
        path:"/pay/method",
        title:"付款資訊" ,
        component : ChoosePayment ,
        exact : true 
    },
    {
        path:"/map",
        title:"地圖資訊" ,
        component : Map ,
        exact : true 
    },
    {
        path:"/privacy",
        title:"隱私權政策" ,
        component : Privacy ,
        exact : true 
    },
    {
        path:"/terms",
        title:"服務條款" ,
        component : Terms ,
        exact : true 
    }
]


export default routes;