import { CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { AppProvider } from '@store/ContextStore';
import { LineLIFFProvider } from '@utils/LineLIFFProvider';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from "@assets/styles/theme";
import { SnackbarProvider } from 'notistack';

const liffId = '1656386892-zEgwd18o'; //daysmart
// const liffId = '1655744597-O1ber7Ye'; //iOShop Demo

const Application = () =>{
  
  return (
    <>
      <ThemeProvider theme={responsiveFontSizes(theme)}>
      <SnackbarProvider 
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <AppProvider>
        <LineLIFFProvider liffId = {liffId}>
          <CssBaseline />
          <App />
        </LineLIFFProvider>
      </AppProvider>
      </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

ReactDOM.render(
  <Application />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
