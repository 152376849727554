import { createContext ,Dispatch, useReducer} from "react";
import ChargeBoxActions from "@store/actions/chargeBoxActions";
import FetchActions from "@store/actions/fetchActions";
import UserInfoActions from "@store/actions/userInfoActions";
import HeaderInfoActions from "@store/actions/headerInfoActions";
import chargeBoxReducer, { ChargeBoxState, INITIAL_CHARGEBOX } from "@store/reducer/chargeBoxReducer";
import fetchReducer, { FetchState, INITIAL_FETCH_STATE } from "@store/reducer/fetchReducer";
import userInfoReducer, { UserInfoState, INITIAL_USER_INFO_STATE } from "@store/reducer/userInfoReducer";
import headerInfoReducer, { HeaderInfoState, INITIAL_HEADERINFO } from "@store/reducer/headerInfoReducer";

type InitialStateType = {
    chargeBox: ChargeBoxState;
    fetch: FetchState;
    user: UserInfoState;
    header: HeaderInfoState
  };
  
const initialState = {
    chargeBox: INITIAL_CHARGEBOX,
    fetch: INITIAL_FETCH_STATE,
    user:INITIAL_USER_INFO_STATE,
    header : INITIAL_HEADERINFO
};

const AppContext = createContext<{
    state: InitialStateType ;
    dispatch: Dispatch<ChargeBoxActions | FetchActions | UserInfoActions | HeaderInfoActions> ;
}>({
    state: initialState ,
    dispatch: () => null
});

const mainReducer = (
    { chargeBox, fetch , user , header}: InitialStateType,
    action: ChargeBoxActions | FetchActions | UserInfoActions |HeaderInfoActions
  ) => ({
    chargeBox: chargeBoxReducer(chargeBox, action as ChargeBoxActions),
    fetch: fetchReducer(fetch, action as FetchActions),
    user: userInfoReducer(user, action as UserInfoActions) ,
    header : headerInfoReducer(header,action as HeaderInfoActions)
  });
  
  const AppProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(mainReducer, initialState);
  
    return (
      <AppContext.Provider value={{ state, dispatch }}>
        {children}
      </AppContext.Provider>
    );
  };
  
  export { AppProvider, AppContext };