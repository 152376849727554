import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: '#4bb6ca',
      main: '#1EA4BD',
      dark: '#157284',
      contrastText: '#fff',
    },
    secondary :{
      main: '#11cb5f',
    }
  } 
});

theme.components = {
  MuiContainer:{
    styleOverrides:{
      root:{
        height:'calc(100vh - 56px - 2vh)' ,
        [`${theme.breakpoints.up('xs')} and (orientation: Landscape)`]: {
          height:'calc(100vh - 48px - 2vh)'
        },
        [theme.breakpoints.up('sm')]: {
          height:'calc(100vh - 64px - 2vh)'
        },
      }
    }
  }
}

/*
const theme = createMuiTheme({

  palette: {
    primary: {
      light: '#4bb6ca',
      main: '#1EA4BD',
      dark: '#157284',
      contrastText: '#fff',
    },
    secondary :{
      main: '#11cb5f',
    }
  } ,
  components: {
    MuiAppBar:{
      styleOverrides:{
        root:{
          height:'56px'
        }
      }
    },
    MuiContainer:{
      styleOverrides:{
        root:{
          height:'calc(100vh - 56px - 2vh)',
          [`${breakpoints.up("xs")} and (orientation: landscape)`]: {
            height: `calc(100vh - 48px)`
          }
        }
      }
    }
  }
  // overrides : {
  //   MuiContainer : {
  //     root:{
  //       marginTop:'20px' ,
  //       marginBottom :'20px'
  //     }
  //   } ,
  // }
});
*/

export default theme;