
// baseUrl
export const baseUrl = '/chargecms';

export const POST_REFRESHTOKEN_API = `${baseUrl}/auth/refreshToken/`;
export const POST_LINE_API = `${baseUrl}/user/register/line`;
export const POST_GET_CHARGEBOX_API = `${baseUrl}/user/chargebox/`;
export const GET_CHARGEBOX_API = `${baseUrl}/chargebox/%s/`;
export const GET_USER_INFO_API = `${baseUrl}/user/`;

export const POST_STARTSTOP_CHARGEBOX_API = `${baseUrl}/chargebox/%s/command/recharge`;
export const POST_TRANSACTION_Data_API = `${baseUrl}/pay/transaction/`;

const APIs = {
    POST_REFRESHTOKEN_API : POST_REFRESHTOKEN_API,
    POST_LINE_API : POST_LINE_API ,
    POST_GET_CHARGEBOX_API : POST_GET_CHARGEBOX_API,
    GET_CHARGEBOX_API : GET_CHARGEBOX_API ,
    POST_STARTSTOP_CHARGEBOX_API : POST_STARTSTOP_CHARGEBOX_API,
    POST_TRANSACTION_Data_API : POST_TRANSACTION_Data_API,
    GET_USER_INFO_API:GET_USER_INFO_API
}
export default APIs;