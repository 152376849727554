
import HeaderInfoActions from '@store/actions/headerInfoActions';

/** State 接口 */
export interface HeaderInfoState {
  title: string
  icon :string
}

/** 初始狀態 */
export const INITIAL_HEADERINFO: HeaderInfoState = {
  title: "" ,
  icon :""
}



/** 默認 reducer */
export default function headerInfoReducer(state: HeaderInfoState = INITIAL_HEADERINFO, action: HeaderInfoActions) {
  switch (action.type) {
    case 'CHANGE_HEADER': {
      return {
        ...state,
        title: action.payload.title,
        icon : action.payload.icon,
      }
      
    }

    default:
      return state
  }
}
