
import axios, { ResponseType, Method, AxiosPromise, AxiosResponse } from 'axios'
import * as apis from '@config/apis';
import { Dispatch } from 'react';
import FetchActions from '@store/actions/fetchActions';
import { getLocalStore, getSessionStore, setSessionStore } from '@utils/util';

/** 默認請求超過逾時時間 */
const REQUEST_TIME_OUT = 10000;

/** token過期錯誤提示次數 */ 
let expiredCount = 0;

/** request 參數接口類型 */
export interface Params {
    url: string
    method?: Method
    params?: { [key:string] : any}
    data?: { [key:string] : any} | string
    responseType?: ResponseType
}

const tokenExpired = () => {
    if(!expiredCount){
        expiredCount += 1 ;
    }

    
}
axios.defaults.withCredentials = true
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

/** 通用網路請求 */
export const requestFn = (dispatch : Dispatch<FetchActions> , params:Params): AxiosPromise => {

    return new Promise((resolve , reject) => {
        dispatch({
            type:'fetch_begin' ,
            payload : {
                pageLoading : true
            }
        });
        axios.interceptors.response.use(
            response => {
                if (response && response.status === 200 && response.data && response.data.token) {
                    axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`
                    // setSessionStore('account_token', response.data.token)
                }
                return response;
            } ,
            error => {
                const config = error.config ;
                if(error.response && error.response.status === 401){    
                    return new Promise((resolve) => {

                        const refreshToken = getLocalStore("refresh_token") || getSessionStore("refresh_token");
                        
                        axios.post(`${apis.POST_REFRESHTOKEN_API}` , { refreshToken : refreshToken} ,
                        {
                            headers : {
                                Authorization :'Bearer ' + refreshToken
                            } 
                        })
                        .then( response => {
                            
                            setSessionStore('account_token', response.data.token);
                            config.headers['Authorization'] = 'Bearer ' + response.data.token
                            
                            resolve(axios(config));
                        }).catch( error => {
                            console.log(`Respone Error : ${JSON.stringify(error)}`);  
                            tokenExpired();
                            reject(error);
                        });
                        
                    });
                } else {
                    if(error.message.includes('timeout')){
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const result : AxiosResponse<any> = {
                            data : {msg : "請求超時"} ,
                            status : 408 ,
                            statusText : "請求超時" ,
                            headers : error.config.headers ,
                            config : error.config
                        }
                        return resolve(result);
                    } else {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const result: AxiosResponse<any> = {
                            data: error.response && error.response.data ? error.response.data : null,
                            status: error.response && error.response.status ? error.response.status : 500,
                            statusText: error.message,
                            headers: error.config.headers,
                            config: error.config
                        }
                        return resolve(result)
                    }
                }
            }
        );
        const account_token = getSessionStore('account_token');
        axios.request({
            url : params.url ,
            method : params.method || 'get' ,
            // baseURL:`${baseUrl}` ,
            params : params.params || {} ,
            headers : {
                ...(account_token ? { Authorization: `Bearer ${account_token}` } : {}),
                // 某些請求 data直接是JSON 或字串，需要改變 Content-Type 值
                ...(params.data && typeof params.data === 'string' ? { 'Content-Type': 'text/uri-list' } : {})
            },
            data : 
                params.data ,
            timeout : REQUEST_TIME_OUT ,
            validateStatus: status => {
                // 除了401錯誤，所有響應碼都接受，每個請求錯誤都單獨處理。
                return status !== 401
            },
            responseType: params.responseType || 'json',
            withCredentials: true
        })
        .then(response =>{
            dispatch({
                type: 'fetch_success',
                payload: {
                    pageLoading: false
                }
            });
            resolve(response);

        })
        .catch(error => {
            dispatch({
                type: 'fetch_failed',
                payload: {
                    pageLoading: false
                }
            });
            reject(error);
        });
    })
}