import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import './App.css';
import { createBrowserHistory } from 'history'
import loadable from '@loadable/component';
import Loading from '@components/Loading';
import { AppContext } from '@store/ContextStore';
import { CircularProgress, LinearProgress } from '@mui/material';
import routes from '@routes/routes';
import Page from '@components/Page';
import customRoutes from '@routes/customRoutes';
import BoxContainer from '@components/Custom/BoxContainer';
import { useSnackbar } from 'notistack';

const Main = loadable( () => import('@layouts/Main') , {
  fallback:<Loading />,
} );

const OtherMain = loadable( () => import('@layouts/OtherMain') , {
  fallback:<Loading />,
} );


const history = createBrowserHistory();

const App = ()=> {
  const { state } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // if(state.user.lineId){
          
    //   setLoading(false);
    // }
  }, [state.user.lineId]);

  return (
    <> 
    {
      loading ? <LinearProgress/> : 
      <Router history={history}>
          <Switch>  
            <Main>
              {
                routes.map( (route , index ) => 
                  <Page
                    key = {index}
                    path = {route.path}
                    exact = {route.exact}
                    title = {route.title}
                    component = {route.component}
                  >
                  </Page>
                )
              }
            </Main>
            
          </Switch>
      </Router>
    }
    </> 
  );
}

export default App;
