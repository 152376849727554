import { FunctionComponent, useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";


export interface IPageProps extends RouteProps {
    title?: string
    children? :Array<IPageProps>
}

const Page:FunctionComponent<IPageProps> = props =>{

    useEffect(()=>{
        
    },[]);

    const { title, ...rest } = props;

    return <Route {...rest} />

}

export default Page;