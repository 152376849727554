import UserInfoActions from '@store/actions/userInfoActions';

/** State 接口 */
export interface UserInfoState {
  lineId: string
  name:string
  mail:string
  isModified:boolean
}

/** 初始狀態 */
export const INITIAL_USER_INFO_STATE: UserInfoState = {
  lineId : "" ,
  name : "",
  mail:"",
  isModified:false
}


/** 默認 reducer */
export default function userInfoReducer(state: UserInfoState = INITIAL_USER_INFO_STATE, action: UserInfoActions) {
  switch (action.type) {
    case 'CHANGE_USER_INFO': {
      return {
        ...state,
        lineId: action.payload.lineId,
        name:action.payload.name,
        mail:action.payload.mail,
        isModified:action.payload.isModified,
      }
    }
    case 'CHANGE_USER_MAIL': {
      return {
        ...state,
        mail:action.payload.mail,
      }
    }
    case 'CHANGE_USER_MODIFIED': {
      return {
        ...state,
        isModified:action.payload.isModified,
      }
    }

    default:
      return state
  }
}
